<template>
  <div class="font-source">
    <section class="block items-center">
      <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">Payments</h4>
    </section>

    <section class="my-9">
      <div class="table-box">
        <h4 class="px-2 mb-5">
          Search for recieved payments
          <span class="text-gray-400">(Payment Reference, Customer ID)</span>
        </h4>

        <api-table
          apiUrl="/transaction/payments"
          :fields="fields"
          :show-page="true"
          :show-search="true"
          :show-date="false"
          detail-row-component="agent-drop-down"
          :bus="bus"
          :loadOnStart="false"
        >
          <template slot="invoiceNumber" slot-scope="props">
            <div @click="onCellClicked(props.rowData)" class="cursor-pointer">
              <span>{{ props.rowData.invoiceNumber }} </span>
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <div class="flex gap-3">
              <button
                class="px-2.5 py-1.5 text-white bg-blue-500 rounded"
                @click="viewInvoice(props.rowData)"
              >
                View
              </button>
            </div>
          </template>
        </api-table>
      </div>
    </section>

    <Modal v-if="showView" v-model="showView" size="md">
      <Payment :invoice="invoice" />
    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "@/components/app/Modal.vue";
import Payment from "@/components/admin/Payment.vue";
import AgentDropDown from "@/components/admin/AgentDropDown";
Vue.component("agent-drop-down", AgentDropDown);
export default {
  name: "AgentPayments",

  components: {
    Modal,
    Payment,
  },

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      showView: false,
      invoice: null,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "customerID",
          title: "Customer ID",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} uppercase`,
        },
        {
          name: "metaData.OriginatorAccountName",
          title: "Account Name",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => {
            return val ? val : "<i class='text-red-300'>No Customer<i/>";
          },
        },
        {
          name: "paymentChannel",
          title: "Payment Channel",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "paymentReference",
          title: "Payment Reference",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "amount",
          title: "Amount(₦)",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} `,
          formatter: (value) => this.moneyFormatter(value),
        },
        {
          name: "status",
          title: "Status",
          titleClass: `${tc} text-left uppercase text-xs ${sv}`,
          dataClass: `${dc} uppercase ${sv} `,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  methods: {
    onAction(action, data, index) {
      console.log("slot action: " + action, data, index);
    },
    onAddItem() {
      this.showCreate = false;
    },
    onAddSubItem() {
      this.showCreateSub = false;
    },
    onCellClicked(data) {
      this.bus.$emit("cellClicked", data);
    },
    viewInvoice(data) {
      // console.log(i, data);
      this.invoiceNumber = data.customerID;
      this.showView = true;
      this.invoice = data;
    },
    async deleteInvoice(da) {
      try {
        const rep = await this.$swal({
          icon: "question",
          text: "Are you sure you want to delete this invoice?",
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        const res = await this.$http.delete("/invoice/" + da.invoiceNumber);

        if (!res) {
          return;
        }

        this.$events.fire("table-refresh");

        this.$swal({
          icon: "success",
          text: "Deleted Successfully",
        });
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
</style>
